<template>
  <a-modal
    v-model="visible"
    class="modal-logout"
    title="Menú"
    :maskClosable="false"
  >
    <div class="modal-content">
      {{ message }}
    </div>
    <template slot="footer">
      <a-button key="back" @click="$emit('cancel')">
        Cerrar
      </a-button>
      <a-button @click="$emit('confirm')" class="btn-confirm">
        Confirmar
      </a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "ConfirmDecline",
  props: {
    visible: {
      default: false,
    },
    message: null,
  },
};
</script>