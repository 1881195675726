<template>
  <div>
    <WaitingOrder
      v-if="!isOrderActive"
      :currentTime="currentTime"
      :establishmentStatus="establishmentStatus"
      :establishmentSchedule="establishmentSchedule"
    />
    <a-row type="flex" v-else>
      <a-col class="order-active-sidebar" :flex="1">
        <div class="order-content">
          <div class="order-list">
            <div
              @click="openOrder(order, index)"
              v-for="(order, index) in getActiveOrders"
              :key="index"
              :class="
                activeIndex === index ? 'history-order active' : 'history-order'
              "
            >
              <div class="order-element">
                <a-row type="flex" class="history-order-about">
                  <a-col class="order-detail" :span="6"
                    ><strong>#{{ order.id }}</strong></a-col
                  >
                  <a-col
                    class="order-detail"
                    :class="isPending(order)"
                    :span="12"
                  >
                    {{ getStatus(order) }}
                  </a-col>
                  <a-col :span="6" v-if="isXpress(order)"
                    ><div v-if="xpressOrderType(order)" class="express">
                      Express
                    </div>
                    <div v-else class="auto">Auto</div>
                  </a-col>
                </a-row>
                <a-row type="flex" class="order-deliverier-details">
                  <a-col :span="6">
                    <DisplayIcon
                      style="width: 30px"
                      v-if="!isDeliverierWithImage(order)"
                    />
                    <img v-else :src="getDeliverierImage(order)" />
                  </a-col>
                  <a-col class="deliverier-details" :span="18">
                    <span>{{ getDeliverier(order) }}</span>
                  </a-col>
                </a-row>
                <a-row
                  :class="`history-order-price order-elapsed ${
                    hasCookingTimeEnded(order) ? 'cooking-time-ended' : ''
                  }`"
                >
                  {{ getOrderStatus(order) }}{{ order.elapsed_time }}
                </a-row>
              </div>
              <a-divider type="horizontal" />
            </div>
          </div>
        </div>
      </a-col>
      <EstablishmentStatusOrder
        :establishmentStatus="establishmentStatus"
        :establishmentSchedule="establishmentSchedule"
        :establishmentMenuName="establishmentMenuName"
        v-if="!selectedOrder"
      />
      <NewOrder
        :selectedOrder="updateSelectedOrder()"
        :isXpressPriceQr="
          isXpress(this.selectedOrder) && xpressOrderType(this.selectedOrder)
        "
        v-if="isNewOrder"
        v-on:acceptOrder="acceptOrder"
        v-on:adjustOrder="adjustOrder"
        v-on:declineOrder="declineOrder"
      />
      <OrderAccepted
        :selectedOrder="updateSelectedOrder()"
        :isXpressPriceQr="
          isXpress(this.selectedOrder) && xpressOrderType(this.selectedOrder)
        "
        :currentTime="currentTime"
        v-on:finishOrder="finishOrder"
        v-on:addExtraTime="addExtraTime"
        v-on:cancelOrder="cancelOrder"
        v-on:adjustOrder="adjustOrder"
        v-if="isAcceptedOrder"
      />
    </a-row>
    <AddTime
      ref="cookingTimesModal"
      :visible="isAddTimeVisible"
      v-on:cancel="addTimeVisible = false"
      v-on:addExtraTime="addExtraTime"
    />
  </div>
</template>
<script>
import WaitingOrder from "@/components/orders/WaitingOrder";
import NewOrder from "@/components/orders/new/NewOrder.vue";
import OrderAccepted from "@/components/orders/accepted/OrderAccepted.vue";
import EstablishmentStatusOrder from "@/components/orders/EstablishmentStatusOrder";
import oStatus from "@/utils/orderStatus.js";
import order from "@/utils/xpressStatus.js";
import DisplayIcon from "@/icons/DisplayDeliverier";
import AddTime from "@/components/orders/accepted/AddTime";
import errorM from "@/utils/errors.js";

import moment from "moment";
moment.locale("es");

const dayOfWeekF = {
  0: 6,
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
};

const schedule = {
  CLOSED: 0,
  OPENED: 1,
  PAUSED: 2,
};

export default {
  name: "OrderActive",
  components: {
    AddTime,
    WaitingOrder,
    NewOrder,
    OrderAccepted,
    EstablishmentStatusOrder,
    DisplayIcon,
  },
  data() {
    return {
      addTimeVisible: false,
      data: Object,
      loaded: false,
      activeIndex: undefined,
      selectedOrder: null,
      activeOrders: null,
      showActiveOrders: false,
      newAvailableOrder: false,
      scheduleStatus: 0,
      scheduleCurrent: 0,
    };
  },
  methods: {
    isDeliverierAssigned(order) {
      return order.delivery_man_id !== null;
    },
    isDeliverierWithImage(order) {
      return order.delivery_man_id && order.delivery.person.photo;
    },
    hasCookingTimeEnded(order) {
      return order.cooking_time_ended;
    },
    getDeliverierImage(order) {
      return order.delivery.person.photo.includes("http")
        ? order.delivery.person.photo
        : `${this.$store.getters.getServer}/images/deliveries/${order.delivery.person.photo}`;
    },
    isXpress(order) {
      return typeof order.type !== "undefined";
    },
    xpressOrderType(orderReceived) {
      return orderReceived.type === order.type.XPRESS;
    },
    openOrder(order, index) {
      this.selectedOrder = order;
      this.activeIndex = index;
    },
    getStatus(order) {
      if (order.delivery_man_id) {
        return oStatus.receive.REP_ON_GOING;
      }
      return oStatus.orderStatus[order.status];
    },
    getOrderStatus(order) {
      if (order.status === oStatus.response.ACCEPT && order.type === undefined) {
        return this.hasCookingTimeEnded(order)
          ? "Cocina finalizada "
          : "Restan ";
      }
      return "";
    },
    getOrders() {
      let orders = this.$store.getters.getActiveOrders;
      if (typeof orders === "undefined") {
        orders = [];
      }
      return orders;
    },
    isPending(order) {
      return order.status === oStatus.response.NEW
        ? "order-pending"
        : "order-ready";
    },
    finishOrder(isXpress) {
      this.takeOrder(oStatus.response.READY, isXpress);
    },
    declineOrder(isXpress) {
      this.takeOrder(oStatus.response.REJECT, isXpress);
    },
    cancelOrder(isXpress) {
      this.takeOrder(oStatus.response.CANCEL, isXpress);
    },
    acceptOrder(isXpress) {
      this.assignTime(isXpress);
    },
    getDeliverier(order) {
      if (order.delivery_man_id) {
        const deliverier = order.delivery;
        return `${deliverier.person.first_name} ${deliverier.person.last_name}`;
      }
      return "Sin repartidor";
    },
    getTime(min) {
      let hours = Math.trunc(min / 60);
      let minutes = min % 60;
      const time =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes) +
        ":00";
      return time;
    },
    assignTime(isXpress) {
      if (isXpress) {
        this.selectedOrder.isXpress = isXpress;
      }
      this.addTimeVisible = true;
    },
    async adjustOrder(oid, total) {
      const payload = {
        oid,
        total,
      };

      let route = "order/disponibility-adjustment";
      if (typeof this.selectedOrder.type !== "undefined") {
        route = "xpress-order/disponibility-adjustment";
      }

      const resp = await this.$store.dispatch("post", {
        route: route,
        data: payload,
      });

      if (resp.ok) {
        this.$message.success("Ajuste agregado con éxito", 5);
        // update order
        let activeOrders = this.$store.getters.getActiveOrders;
        // Update status and updated at
        const type = this.selectedOrder.type;
        activeOrders.filter(function (o) {
          if (o.id === oid && o.type === type) {
            o.total = resp.total;
            o.disponibility_adjustment = total;
          }
        });

        this.$store.commit("set_orders_active", activeOrders);
      } else {
        this.$message.error("Error al crear ajuste");
      }
    },
    async takeOrder(orderSta, isXpress) {
      let description = "";

      try {
        const orderStatus = {
          status: orderSta,
          oid: this.selectedOrder.id,
        };

        let route = "order/accept-or-reject-order-by-establishment";
        if (isXpress) {
          route = "order/accept-or-reject-xpress-order-by-establishment";
        }

        const resp = await this.$store.dispatch("post", {
          route: route,
          data: orderStatus,
        });

        if (resp && resp.result) {
          // update order
          let activeOrders = this.$store.getters.getActiveOrders;
          // Update status and updated at
          const type = this.selectedOrder.type;


          activeOrders = activeOrders.filter(function (o) {
            if (o.id === resp.id) {
              o.status = resp.status;
            }
            if (!(o.id === resp.id && o.type === type && resp.status !== oStatus.response.READY)) {
              return o;
            }
          });

          if (orderSta !== oStatus.response.REJECT && orderSta !== oStatus.response.CANCEL) {
            // Update elapsed time
            activeOrders[this.activeIndex].elapsed;
          } else {
            this.activeIndex = 'undefined';
          }

          this.$store.commit("set_orders_active", activeOrders);

          description = `La orden #${this.selectedOrder.id} cambió a status ${oStatus.orderStatus[orderStatus.status]} con éxito`

          this.$notification.success({
            message: "Actualizar orden",
            description,
            duration: 5,
          })
          
          this.selectedOrder = this.activeIndex !== 'undefined' ? activeOrders[this.activeIndex] : null;
        } else {
          // Show error message
          this.$notification.error({
            message: "Actualizar orden",
            description: errorM.orders[resp ? resp.code : "ERROR"],
            duration: 5,
          });
        }
        
      } catch (err) {
        description = `Error al actualizar orden #${this.selectedOrder.id}`
        this.$notification.error({
          message: "Actualizar orden",
          description,
          duration: 5,
        })
      }
    },
    async addExtraTime(time) {
      try {
        const timeUpdated = {
          cooking_time: time,
          oid: this.isXpress(this.selectedOrder)
            ? "e" + this.selectedOrder.id
            : this.selectedOrder.id,
          status: oStatus.response.ACCEPT,
        };

        this.addTimeVisible = false;

        const resp = await this.$store.dispatch("post", {
          route: "order/update-order-status",
          data: timeUpdated,
        });

        // If time was updated
        if (resp.status) {
          // update order
          let activeOrders = this.$store.getters.getActiveOrders;

          this.selectedOrder.cooking_time = time;
          // Update cooking times
          activeOrders.filter(
            function (o) {
              if (
                o.id === this.selectedOrder.id &&
                o.type === this.selectedOrder.type
              ) {
                const timeReady = this.getTime(time);
                o.cooking_time = time;
                o.status = resp.status;
                o.updated_at = resp.updated_at;
                o.elapsed_time = timeReady;
              }
            }.bind(this)
          );

          this.$store.commit("set_orders_active", activeOrders);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.$refs.cookingTimesModal.resetModalStatus();
      }
    },
    updateSelectedOrder() {
      if (this.selectedOrder) {
        this.selectedOrder = this.getActiveOrders.find(
          (o) => o.id === this.selectedOrder.id
        );
        return this.selectedOrder;
      }
      return null;
    },
    getMillisec(now) {
      now = now.split(":");
      return now[0] * (60000 * 60) + now[1] * 60000 + now[2];
    },
    timeOnRange(timeEstablishment, timeNow) {
      const millisecNow = timeNow;

      let opened = false;
      let scheduleCurrent = 0;

      const todayDate = moment(timeNow).format("YYYY-MM-DD");

      for (const _schedule of timeEstablishment) {
        let now = _schedule.opens_at;
        const milisecOpens = moment(todayDate + " " + now);

        now = _schedule.closes_at;
        const milisecCloses = moment(todayDate + " " + now);

        if (moment(millisecNow).isBetween(milisecOpens, milisecCloses)) {
          opened = true;
          this.scheduleStatus = schedule.OPENED;
          break;
        } else {
          opened = false;
          this.scheduleStatus = schedule.CLOSED;
        }

        scheduleCurrent++;
      }
      this.scheduleCurrent = scheduleCurrent;
      if (!opened && timeEstablishment.length > 1) {
        const firstScheduleMillisec = moment(
          todayDate + " " + timeEstablishment[0].opens_at
        );

        const lastScheduleMillisec = moment(
          todayDate +
            " " +
            timeEstablishment[timeEstablishment.length - 1].closes_at
        );

        if (
          moment(millisecNow).isBetween(
            firstScheduleMillisec,
            lastScheduleMillisec
          )
        ) {
          this.scheduleStatus = schedule.PAUSED;
        }
      }
    },
  },
  computed: {
    establishmentMenuName() {
      return this.$store.getters.establishmentMenu[0].name.toUpperCase();
    },
    getActiveOrders() {
      return this.$store.getters.getActiveOrders;
    },
    orderInRange() {
      return this.data.orders.length > 0 ? true : false;
    },
    isOrderActive() {
      const orders = this.getOrders();
      return orders !== null && orders.length !== 0;
    },
    isNewOrder() {
      const newOrder =
        this.selectedOrder !== null &&
        this.selectedOrder.status === oStatus.response.NEW;
      return newOrder;
    },
    isAcceptedOrder() {
      const newOrder =
        this.selectedOrder !== null &&
        (this.selectedOrder.status === oStatus.response.ACCEPT ||
          this.selectedOrder.status === oStatus.response.READY ||
          this.selectedOrder.status === oStatus.response.DELIVERIER_ASSIGNED);
      return newOrder;
    },
    currentTime() {
      return this.$store.getters.getCurrentTime;
    },
    establishmentStatus() {
      return this.$store.getters.getEstablishmentStatus;
    },
    establishmentSchedule() {
      const dayOfWeek =
        dayOfWeekF[moment(this.$store.getters.getCurrentTime).day()];

      if (this.$store.getters.getSchedules) {
        const schedules = this.$store.getters.getSchedules.filter(
          (s) => s.day_of_week === dayOfWeek
        );
        this.timeOnRange(schedules, this.$store.getters.getCurrentTime);
        if (schedules.length > 0) {
          let _currentSchedule = schedules[0];
          if (this.scheduleStatus === schedule.OPENED) {
            _currentSchedule = schedules[this.scheduleCurrent];
          } else if (this.scheduleStatus === schedule.PAUSED) {
            _currentSchedule = schedules[schedules.length - 1];
          }
          return {
            has_schedules: true,
            opens_at: moment(_currentSchedule.opens_at, "HH:mm:ss").format(
              "hh:mm A"
            ),
            closes_at: moment(_currentSchedule.closes_at, "HH:mm:ss").format(
              "hh:mm A"
            ),
            status: this.scheduleStatus,
          };
        }
      }
      return {
        has_schedules: false,
        opens_at: "",
        closes_at: "",
      };
    },
    isAddTimeVisible() {
      return !this.$store.getters.isNewOrder && this.addTimeVisible;
    },
  },
};
</script>
