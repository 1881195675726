<template>
  <i
    aria-label="icon: display"
    class="anticon"
    :style="'margin-left:' + marginLeft"
  >
    <svg
      width="40"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.7261" cy="20.1071" r="19.8929" fill="#EBEBEB" />
    </svg>
  </i>
</template>
<script>
export default {
  props: {
    marginLeft: {
      type: String,
      default: "0",
    },
  },
};
</script>
