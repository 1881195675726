<template>
  <a-col class="order-content-details" :flex="21">
    <div class="history-order-details establishment-status-order">
      <div class="order-container">
        <a-row class="status-order order-logo-establishment" type="flex">
          <EstablishmentStatus
            :establishmentStatus="establishmentStatus"
            :establishmentSchedule="establishmentSchedule"
            :establishmentMenuName="establishmentMenuName"
          />
        </a-row>
      </div>
    </div>
  </a-col>
</template>
<script>
import EstablishmentStatus from "@/components/orders/EstablishmentStatus";
export default {
  name: "EstablishmentStatusOrder",
  components: {
    EstablishmentStatus,
  },
  props: {
    establishmentStatus: null,
    establishmentSchedule: null,
    establishmentMenuName: null,
  },
};
</script>
