<template>
  <i
    aria-label="icon: lock"
    class="anticon"
    :style="'margin-left:' + marginLeft"
  >
    <svg
      width="7"
      height="9"
      viewBox="0 0 7 9"
      fill="none"
      :style="'margin-top:' + marginTop"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.16523 2.16306C5.16523 1.16157 4.35337 0.349701 3.35187 0.349701V0.349701C2.35038 0.349701 1.53851 1.16157 1.53851 2.16306V3.43276C1.53851 4.43425 2.35038 5.24612 3.35187 5.24612V5.24612C4.35337 5.24612 5.16523 4.43425 5.16523 3.43276V2.16306ZM4.39769 2.13828C4.39749 1.56058 3.929 1.09242 3.3513 1.09262V1.09262C2.7736 1.09282 2.30544 1.56131 2.30564 2.13902L2.306 3.1645C2.3062 3.74221 2.77469 4.21036 3.35239 4.21016V4.21016C3.9301 4.20996 4.39825 3.74147 4.39805 3.16377L4.39769 2.13828Z"
        fill="#F3AE46"
      />
      <rect
        x="0.433716"
        y="2.96481"
        width="5.8363"
        height="5.93642"
        rx="1"
        fill="#F3AE46"
      />
      <path
        d="M3.69342 6.31009C4.04773 6.17303 4.29901 5.82907 4.29901 5.42639C4.29901 4.90329 3.87494 4.47922 3.35181 4.47922C2.82874 4.47922 2.40466 4.90329 2.40466 5.42639C2.40466 5.82907 2.65594 6.17303 3.01025 6.31009V7.06586C3.01025 7.25452 3.16321 7.40744 3.35181 7.40744C3.54047 7.40744 3.69342 7.25452 3.69342 7.06586V6.31009Z"
        fill="white"
      />
    </svg>
  </i>
</template>
<script>
export default {
  props: {
    marginLeft: {
      type: String,
      default: "0",
    },
    marginTop: {
      type: String,
      default: "-5px",
    },
  },
};
</script>
