<template>
  <i aria-label="icon: display" class="anticon" :style="'margin-left:' + marginLeft">
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13.312" cy="13.0099" r="12.8516" fill="#C4C4C4" />
    </svg>
  </i>
</template>
<script>
export default {
  props: {
    marginLeft: {
      type: String,
      default: "0",
    },
  },
};
</script>
