<template>
  <div class="waiting-order">
    <a-row type="flex">
      <a-col :span="10" class="message"> Esperando órdenes </a-col>
      <a-col :span="14" class="current-date">
        {{ date }}
      </a-col>
    </a-row>
    <EstablishmentStatusNoOrder
      :establishmentStatus="establishmentStatus"
      :establishmentSchedule="establishmentSchedule"
    />
  </div>
</template>
<script>
import moment from "moment";
import EstablishmentStatusNoOrder from "@/components/orders/EstablishmentStatusNoOrder";
export default {
  name: "WaitingOrder",
  components: {
    EstablishmentStatusNoOrder,
  },
  props: {
    currentTime: null,
    establishmentStatus: null,
    establishmentSchedule: null,
  },
  created() {
    moment.locale("es");
  },
  computed: {
    date() {
      const date = moment(this.currentTime);
      return date.format("dddd D [de] MMMM h:mm A");
    },
  },
};
</script>
