<template>
  <a-modal
    v-model="visible"
    class="modal-user create-user modal-add-time"
    title="Aceptar orden"
    :maskClosable="false"
  >
    <a-row type="flex" class="add-time time-hook" justify="space-between">
      <span class="add-time-hooks">Atajos de tiempo de cocina (en minutos)</span>
    </a-row>
    <a-row type="flex" class="add-time" justify="space-between">
      <a-col :span="5" class="details-button" @click="updateTime(10)">
        <div :class="`details noselect circle-button ${isActive(10) ? 'active' : ''}`">10</div>
      </a-col>
      <a-col :span="5" class="details-button" @click="updateTime(15)">
        <div :class="`details noselect circle-button ${isActive(15) ? 'active' : ''}`">15</div>
      </a-col>
      <a-col :span="5" class="details-button" @click="updateTime(20)">
        <div :class="`details noselect circle-button ${isActive(20) ? 'active' : ''}`">20</div>
      </a-col>
      <a-col :span="5" class="details-button" @click="updateTime(30)">
        <div :class="`details noselect circle-button ${isActive(30) ? 'active' : ''}`">30</div>
      </a-col>
    </a-row>
    <a-row type="flex" class="add-time" justify="space-between">
      <a-col :span="8" class="details-button" @click="updateTime(0)">
        <div class="details noselect decrease-time">-5 minutos</div>
      </a-col>
      <a-col :span="8" class="details-button">
        <div class="details no-button selected-time">{{ extraTime }} minutos</div>
      </a-col>
      <a-col :span="8" class="details-button" @click="updateTime(1)">
        <div class="details noselect increase-time">+5 minutos</div>
      </a-col>
    </a-row>
    <template slot="footer">
      <a-button key="back" @click="cancelCookingTimes"> Cancelar </a-button>
      <a-button
        key="create"
        type="primary"
        class="btn-ok"
        :disabled="addingCookingTime"
        @click="addExtraTime"
      >
        Ok
      </a-button>
    </template>
  </a-modal>
</template>

<script>
const MAX_ALLOWED = 40;
const MIN_ALLOWED = 10;
const TIME_INCREMENT = 5;
export default {
  name: "AddTime",
  props: {
    visible: null,
  },
  data() {
    return {
      active: MIN_ALLOWED,
      extraTime: MIN_ALLOWED,
      addingCookingTime: false,
    };
  },
  methods: {
    addExtraTime() {
      this.addingCookingTime = true;
      this.$emit("addExtraTime", this.extraTime);
    },
    cancelCookingTimes() {
      this.resetModalStatus();
      this.$emit('cancel');
    },
    isActive(type) {
      return this.active == type;
    },
    resetModalStatus() {
      this.active = MIN_ALLOWED;
      this.extraTime = MIN_ALLOWED;
      this.addingCookingTime = false;
    },
    resetModalOkButton() {
      this.addingCookingTime = false;
    },
    updateTime(type) {
      if (this.extraTime < MAX_ALLOWED || 
        ((type === 0 || type > 1 && type <= MAX_ALLOWED) && 
        this.extraTime === MAX_ALLOWED)) {

        if (type === 1) {
          this.extraTime += TIME_INCREMENT;
        } else if (type === 0 && this.extraTime > MIN_ALLOWED) {
          this.extraTime -= TIME_INCREMENT;
        } else if (type > 0) {
          this.extraTime = type;
        }
        
      }
      this.active = this.extraTime; 
    },
  },
};
</script>
