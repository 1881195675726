<template>
  <a-col class="order-content-details" :flex="21">
    <div v-if="selectedOrder" class="history-order-details">
      <div class="order-container">
        <a-row type="flex" class="history-order-active">
          <a-col :span="12">
            <a-row class="details"> ¿Quieres aceptar la orden? </a-row>
          </a-col>
          <a-col :span="12" class="details-button" @click="acceptOrder">
            <a-row class="details"> Aceptar </a-row>
          </a-col>
        </a-row>
        <a-row type="flex">
          <a-col class="history-order-id">
            Orden #{{ selectedOrder.id }}
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between">
          <a-col class="history-order-client" :span="11">
            <div class="details">
              <a-col :span="21">
                <a-row class="details-name">Cliente</a-row>
                <a-row class="details-data">{{ clientName }} - <span class="phone">{{ clientPhone }}</span></a-row>
              </a-col>
              <a-col :span="3">
                <div class="details-phone">
                  <a :href="clientPhoneLink">
                    <PhoneIcon />
                  </a>
                </div>
              </a-col>
            </div>
          </a-col>
          <a-col class="history-order-client" :span="12">
            <div class="details">
              <a-col :span="3">
                <DisplayIcon />
              </a-col>
              <a-col :span="18">
                <a-row class="details-name">Repartidor</a-row>
                <a-row class="details-data">...</a-row>
              </a-col>
              <a-col :span="3">
                <div class="details-phone">
                  <PhoneIcon />
                </div>
              </a-col>
            </div>
          </a-col>
        </a-row>
        <Extras :isXpressPriceQr="isXpressPriceQr" :orderTickets="orderTickets" :key="selectedOrder.id" />
        <a-divider type="horizontal" />
        <div class="history-order-totalsub">
          <template v-if="hasEstablishmentShippingCost">
            <a-row type="flex">
              <a-tag class="tag">
                <span>Costo Entrega: ${{ selectedOrder.shipping_cost }}</span>
              </a-tag>
            </a-row>
            <a-row type="flex">
              <a-col :flex="3">Subtotal</a-col>
              <a-col class="total" :flex="3">${{ selectedOrder.total }}</a-col>
            </a-row>
            <a-row type="flex">
              <a-col :flex="3">- Envío absorbido</a-col>
              <a-col class="total" :flex="3">- ${{ selectedOrder.establishment_shipping_cost }}</a-col>
            </a-row>
          </template>
          <a-row type="flex">
            <a-col :flex="3">Total</a-col>
            <a-col class="total" :flex="3">${{ isXpress ? orderTotal : selectedOrder.total }}</a-col>
          </a-row>        </div>
        <PayOnCollect v-if="isPayOnCollect" :order="selectedOrder" />
        <Adjust :order="selectedOrder" v-on:adjustOrder="adjustOrder" />
        <div class="order-comments">
          <a-row type="flex" class="header-comments">
            <span>Nota:</span>
          </a-row>
          <a-row type="flex" class="comments">
            " {{ selectedOrder.comments }} "
          </a-row>
        </div>
        <div class="decline-order">
          <div class="decline-options">
            <span>¿No puedes aceptar esta orden?</span>
            <div class="button" @click="declineOrder">Rechazar orden</div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmDecline v-on:cancel="declineVisible = false" v-on:confirm="confirmDecline" :visible="isDeclineVisible"
      :message="confirmDeclineMessage" />
  </a-col>
</template>
<script>
import ConfirmDecline from "@/components/orders/ConfirmDecline";
import DisplayIcon from "@/icons/Display";
import PhoneIcon from "@/icons/Phone";
import Extras from "@/components/orders/Extras";
import Adjust from "@/components/orders/adjust/Adjust";
import PayOnCollect from "../business-model/PayOnCollect.vue";
export default {
  name: "NewOrder",
  components: {
    Adjust,
    ConfirmDecline,
    DisplayIcon,
    PhoneIcon,
    Extras,
    PayOnCollect
  },
  props: {
    isXpressPriceQr: null,
    selectedOrder: null,
  },
  data() {
    return {
      declineVisible: false,
    };
  },
  methods: {
    adjustOrder(total) {
      this.$emit("adjustOrder", this.selectedOrder.id, total);
    },
    getExtraPrice(extra) {
      return extra.cost ? `$${extra.cost}` : "--";
    },
    declineOrder() {
      this.declineVisible = true;
    },
    confirmDecline() {
      this.declineVisible = false;
      this.$emit("declineOrder", this.isXpress);
    },
    acceptOrder() {
      this.$emit("acceptOrder", this.isXpress);
    },
  },
  computed: {
    confirmDeclineMessage() {
      return "¿Estás seguro que deseas rechazar la orden?";
    },
    isPayOnCollect() {
      return this.selectedOrder.business_model === 'PAY_ON_COLLECT' && !this.isXpress;
    },
    isXpress() {
      return typeof this.selectedOrder.type !== "undefined";
    },
    orderTickets() {
      if (this.isXpress) {
        return this.selectedOrder.xpress_tickets
          ? this.selectedOrder.xpress_tickets
          : null;
      }
      return this.selectedOrder.tickets ? this.selectedOrder.tickets : null;
    },
    orderTotal() {
      return +this.selectedOrder.total - +(this.selectedOrder.establishment_shipping_cost || 0);
    },
    hasEstablishmentShippingCost() {
      return this.isXpress && this.selectedOrder.establishment_shipping_cost > 0;
    },
    clientName() {
      if (this.isXpress) {
        return [
          this.selectedOrder.xpress_client.first_name,
          this.selectedOrder.xpress_client.last_name,
        ].join(" ");
      }
      return [
        this.selectedOrder.person.first_name,
        this.selectedOrder.person.last_name,
      ].join(" ");
    },
    clientPhoneLink() {
      if (this.isXpress) {
        return `tel:${this.selectedOrder.xpress_tickets.phone}`;
      }
      return `tel:${this.selectedOrder.person.phone}`;
    },
    clientPhone() {
      let phone;
      if (this.isXpress) {
        phone = this.selectedOrder.xpress_client.phone;
      } else {
        phone = this.selectedOrder.person.phone;
      }
      const number = phone.slice(-10);
      const codeLength = phone.length - 10;
      const code = phone.slice(0, codeLength);

      const _1_3 = number.slice(0, 3);
      const _3_6 = number.slice(3, 6);
      const _6_10 = number.slice(6, 10);

      return `${code} (${_1_3}) ${_3_6} ${_6_10}`;
    },
    isDeclineVisible() {
      return !this.$store.getters.isNewOrder && this.declineVisible;
    },
  },
};
</script>
