<template>
  <a-col class="order-content-details" :flex="21">
    <div v-if="selectedOrder" class="history-order-details">
      <div class="order-container">
        <a-row v-if="isReady" type="flex" class="history-order-active">
          <a-col :span="12">
            <a-row class="details"> Finalizada en </a-row>
          </a-col>
          <a-col :span="12">
            <a-row class="details"> Finalizada hace </a-row>
          </a-col>
        </a-row>
        <a-row
          v-else-if="hasCookingTime"
          type="flex"
          class="history-order-active"
        >
          <a-col :span="14">
            <a-row class="details"> Tiempo hasta finalizar </a-row>
          </a-col>
          <a-col :span="5">
            <a-row class="details elapsed-time">
              {{ remainingTime }} minutos
            </a-row>
          </a-col>
          <a-col
            v-if="!timeWasAdded"
            :span="5"
            class="details-button"
            @click="addTime"
          >
            <a-row class="details"> Tiempo extra </a-row>
          </a-col>
          <a-col v-else :span="5">
            <a-row class="details added-time">
              +{{ addedTime }} agregados <LockIcon />
            </a-row>
          </a-col>
          <a-col :span="24" class="status-progress">
            <a-col class="history-order-id">
              <a-progress :percent="percent" :show-info="false" />
            </a-col>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between">
          <a-col class="history-order-id order-accepted" :span="11">
            Orden #{{ selectedOrder.id }}
          </a-col>
          <a-col class="history-order-id" :span="12">
            <div class="cancel-order">
              <div class="button" @click="declineOrder">Cancelar</div>
            </div>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between">
          <a-col class="history-order-client" :span="11">
            <div class="details">
              <a-col :span="21">
                <a-row class="details-name">Cliente</a-row>
                <a-row class="details-data">{{ clientName }} - <span class="phone">{{ clientPhone }}</span></a-row>
              </a-col>
              <a-col :span="3">
                <div class="details-phone">
                  <a :href="clientPhoneLink">
                    <PhoneIcon />
                  </a>
                </div>
              </a-col>
            </div>
          </a-col>
          <a-col class="history-order-client" :span="12">
            <div class="details">
              <a-col :span="3" class="user-image">
                <DisplayIcon v-if="!isDeliverierWithImage" />
                <img v-else :src="getDeliverierImage" />
              </a-col>
              <a-col :span="18" class="user-data">
                <a-row class="details-name">Repartidor</a-row>
                <a-row class="details-data">{{ getDeliverier }}</a-row>
              </a-col>
              <a-col :span="3" class="user-phone">
                <div class="details-phone" @click="savePhone(1)">
                  <PhoneIcon />
                  <Dialog
                    v-if="deliverierVisible && deliverierPhone !== ''"
                    :number="deliverierPhone"
                  />
                </div>
              </a-col>
            </div>
          </a-col>
        </a-row>
        <Extras :isXpressPriceQr="isXpressPriceQr" :orderTickets="orderTickets" :key="selectedOrder.id" />
        <a-divider type="horizontal" />
        <div class="history-order-totalsub">
          <a-row type="flex">
            <a-tag class="tag">
              <span>Costo Entrega: ${{ selectedOrder.shipping_cost }}</span>
            </a-tag>
          </a-row>
          <template v-if="hasEstablishmentShippingCost">
            <a-row type="flex">
              <a-col :flex="3">Subtotal</a-col>
              <a-col class="total" :flex="3">${{ selectedOrder.total }}</a-col>
            </a-row>
            <a-row type="flex">
              <a-col :flex="3">- Envío absorbido</a-col>
              <a-col class="total" :flex="3">- ${{ selectedOrder.establishment_shipping_cost }}</a-col>
            </a-row>
          </template>
          <a-row type="flex">
            <a-col :flex="3">Total</a-col>
            <a-col class="total" :flex="3">${{ isXpress ? orderTotal : selectedOrder.total }}</a-col>
          </a-row>
        </div>
        <PayOnCollect v-if="isPayOnCollect" :order="selectedOrder" />
        <Adjust
          v-if="!isReady"
          :order="selectedOrder"
          v-on:adjustOrder="adjustOrder"
        />
        <div class="order-comments">
          <a-row type="flex" class="header-comments">
            <span>Nota:</span>
          </a-row>
          <a-row type="flex" class="comments">
            " {{ selectedOrder.comments }} "
          </a-row>
        </div>
        <div class="decline-order">
          <div class="order-ready">
            <div v-if="isWaitingForDeliverier" class="ready">
              Esperando por el repartidor
            </div>
            <div class="button" v-else-if="!isReady" @click="finishOrder">
              Orden lista
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddTime
      :visible="isExtraTimeVisible"
      v-on:cancel="extraTimeVisible = false"
      v-on:addExtraTime="addExtraTime"
    />
    <ConfirmDecline
      v-on:cancel="declineVisible = false"
      v-on:confirm="confirmDecline"
      :visible="isDeclineVisible"
      :message="confirmDeclineMessage"
    />
  </a-col>
</template>
<script>
import AddTime from "./AddTime";
import DisplayIcon from "@/icons/Display";
import PhoneIcon from "@/icons/Phone";
import LockIcon from "@/icons/Lock";
import Extras from "@/components/orders/Extras";
import Dialog from "@/components/app/Dialog";
import ConfirmDecline from "@/components/orders/ConfirmDecline";
import Adjust from "@/components/orders/adjust/Adjust";
import status from "@/utils/orderStatus.js";
import PayOnCollect from "../business-model/PayOnCollect";
export default {
  name: "OrderAccepted",
  components: {
    AddTime,
    Adjust,
    DisplayIcon,
    PayOnCollect,
    PhoneIcon,
    LockIcon,
    Extras,
    ConfirmDecline,
    Dialog,
  },
  props: {
    isXpressPriceQr: null,
    selectedOrder: null,
    currentTime: null,
  },
  data() {
    return {
      extraTimeVisible: false,
      declineVisible: false,
      deliverierVisible: false,
      clientVisible: false,
    };
  },
  methods: {
    adjustOrder(total) {
      this.$emit("adjustOrder", this.selectedOrder.id, total);
    },
    getExtraPrice(extra) {
      return extra.cost ? `$${extra.cost}` : "--";
    },
    finishOrder() {
      this.$emit("finishOrder", this.isXpress);
    },
    addExtraTime(value) {
      this.extraTimeVisible = false;
      this.$emit("addExtraTime", value, this.selectedOrder);
    },
    addTime() {
      this.extraTimeVisible = true;
    },
    declineOrder() {
      this.declineVisible = true;
    },
    confirmDecline() {
      this.declineVisible = false;
      this.$emit("cancelOrder", this.isXpress);
    },
    savePhone(type) {
      if (type == 1) {
        this.deliverierVisible = !this.deliverierVisible;
      } else {
        this.clientVisible = !this.clientVisible;
      }
    },
  },
  computed: {
    getDeliverier() {
      if (this.selectedOrder.delivery_man_id) {
        const deliverier = this.selectedOrder.delivery;
        return `${deliverier.person.first_name} ${deliverier.person.last_name}`;
      }
      return "...";
    },
    isDeliverierWithImage() {
      return (
        this.selectedOrder.delivery_man_id &&
        this.selectedOrder.delivery.person.photo
      );
    },
    getDeliverierImage() {
      var picture = "";
      if (this.isDeliverierWithImage) {
        picture = this.selectedOrder.delivery.person.photo.includes("http")
          ? this.selectedOrder.delivery.person.photo
          : `${this.$store.getters.getServer}/images/deliveries/${this.selectedOrder.delivery.person.photo}`;
      }
      return picture;
    },
    confirmDeclineMessage() {
      return "¿Estás seguro que deseas cancelar la orden?";
    },
    isPayOnCollect() {
      return this.selectedOrder.business_model === 'PAY_ON_COLLECT' && !this.isXpress;
    },
    isXpress() {
      return typeof this.selectedOrder.type !== "undefined";
    },
    hasCookingTime() {

      // Change this line when cooking times are available
      return false;
      /* return (
        this.selectedOrder.cooking_time !== null &&
        typeof this.selectedOrder.cooking_time !== "undefined"
      ); */
    },
    orderTickets() {
      if (this.isXpress) {
        return this.selectedOrder.xpress_tickets
          ? this.selectedOrder.xpress_tickets
          : null;
      }
      return this.selectedOrder.tickets ? this.selectedOrder.tickets : null;
    },
    orderTotal() {
      return +this.selectedOrder.total - 
        +(this.isEstablishmentShippingCostSet ? this.selectedOrder.establishment_shipping_cost : 0);
    },
    hasEstablishmentShippingCost() {
      return this.isXpress && this.selectedOrder.establishment_shipping_cost > 0;
    },
    isEstablishmentShippingCostSet() {
      return "establishment_shipping_cost" in this.selectedOrder;
    },
    clientName() {
      if (this.isXpress) {
        return [
          this.selectedOrder.xpress_client.first_name,
          this.selectedOrder.xpress_client.last_name,
        ].join(" ");
      }
      return [
        this.selectedOrder.person.first_name,
        this.selectedOrder.person.last_name,
      ].join(" ");
    },
    deliverierPhone() {
      if (this.selectedOrder.delivery_man_id) {
        return this.selectedOrder.delivery.person.phone;
      }
      return "";
    },
    clientPhoneLink() {
      if (this.isXpress) {
        return `tel:${this.selectedOrder.xpress_tickets.phone}`;
      }
      return `tel:${this.selectedOrder.person.phone}`;
    },
    clientPhone() {
      let phone;
      if (this.isXpress) {
        phone = this.selectedOrder.xpress_client.phone;
      } else {
        phone = this.selectedOrder.person.phone;
      }
      const number = phone.slice(-10);
      const codeLength = phone.length - 10;
      const code = phone.slice(0, codeLength);

      const _1_3 = number.slice(0, 3);
      const _3_6 = number.slice(3, 6);
      const _6_10 = number.slice(6, 10);

      return `${code} (${_1_3}) ${_3_6} ${_6_10}`;
    },
    percent() {
      // once we have elapsed, we need to calculate the percent
      if (this.timeElapsed > 0) {
        return (this.timeElapsed * 100) / this.cookingTime;
      } else {
        return 0;
      }
    },
    remainingTime() {
      return this.cookingTime - this.timeElapsed;
    },
    isReady() {
      return (
        this.selectedOrder.status === status.response.READY ||
        this.selectedOrder.status === status.response.DELIVERIER_ASSIGNED
      );
    },
    isDeliverierAssigned() {
      return this.selectedOrder.delivery_man_id !== null;
    },
    isWaitingForDeliverier() {
      return (
        !this.isDeliverierAssigned &&
        this.selectedOrder.status === status.response.READY
      );
    },
    timeElapsed() {
      const updated = new Date(this.selectedOrder.updated_at);
      return Math.floor(
        (this.currentTime.getTime() - updated.getTime()) / 60000
      );
    },
    cookingTime() {
      return (
        this.selectedOrder.cooking_time.estimated_cooking_time +
        this.selectedOrder.cooking_time.time_increment
      );
    },
    timeWasAdded() {
      return this.selectedOrder.cooking_time.time_increment > 0;
    },
    addedTime() {
      return this.selectedOrder.cooking_time.time_increment;
    },
    isExtraTimeVisible() {
      return !this.$store.getters.isNewOrder && this.extraTimeVisible;
    },
    isDeclineVisible() {
      return !this.$store.getters.isNewOrder && this.declineVisible;
    }
  },
};
</script>
