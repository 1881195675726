<template>
  <i
    aria-label="icon: phone"
    class="anticon"
    :style="'margin-left:' + marginLeft"
  >
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.885 10.2896V12.1646C13.8857 12.3386 13.8501 12.5109 13.7803 12.6704C13.7106 12.8299 13.6083 12.973 13.4801 13.0907C13.3518 13.2084 13.2004 13.298 13.0355 13.3537C12.8706 13.4095 12.6959 13.4302 12.5225 13.4146C10.5993 13.2056 8.7519 12.5484 7.12878 11.4958C5.61867 10.5362 4.33836 9.25591 3.37878 7.7458C2.32252 6.11531 1.66518 4.25892 1.46003 2.32705C1.44441 2.15422 1.46495 1.98003 1.52034 1.81557C1.57573 1.65111 1.66476 1.49998 1.78176 1.37182C1.89875 1.24365 2.04116 1.14125 2.1999 1.07113C2.35864 1.00101 2.53024 0.964717 2.70378 0.964553H4.57878C4.8821 0.961568 5.17615 1.06898 5.40613 1.26676C5.63611 1.46455 5.78633 1.73921 5.82878 2.03955C5.90792 2.63959 6.05469 3.22876 6.26628 3.7958C6.35037 4.01951 6.36857 4.26262 6.31872 4.49635C6.26887 4.73008 6.15307 4.94462 5.98503 5.11455L5.19128 5.9083C6.081 7.47302 7.37656 8.76858 8.94128 9.6583L9.73503 8.86455C9.90496 8.69651 10.1195 8.58071 10.3532 8.53086C10.587 8.48101 10.8301 8.49921 11.0538 8.5833C11.6208 8.7949 12.21 8.94166 12.81 9.0208C13.1136 9.06363 13.3909 9.21656 13.5891 9.45049C13.7873 9.68442 13.8926 9.98304 13.885 10.2896Z"
        stroke="#525252"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </i>
</template>
<script>
export default {
  props: {
    marginLeft: {
      type: String,
      default: "0",
    },
  },
};
</script>
