<template>
  <div class="waiting-order">
    <a-row class="status-order order-logo" type="flex">
      <EstablishmentStatus
        :establishmentStatus="establishmentStatus"
        :establishmentSchedule="establishmentSchedule"
      />
    </a-row>
  </div>
</template>
<script>
import EstablishmentStatus from "@/components/orders/EstablishmentStatus";
export default {
  name: "EstablishmentStatusNoOrder",
  components: {
    EstablishmentStatus,
  },
  props: {
    establishmentStatus: null,
    establishmentSchedule: null,
  },
};
</script>
