<template>
  <div class="container-order">
    <div class="current-status" v-if="isOpened">
      <img src="@/assets/establishment-open.svg" />
      <div class="info">
        <p>ABIERTO</p>
        <p class="establishment-hours">CIERRA A LAS {{ getClosesAt }}</p>
        <p class="establishment-hours">MENÚ {{ establishmentMenuName }}</p>
      </div>
    </div>
    <div v-else class="current-status closed">
      <img src="@/assets/establishment-closed.svg" />
      <div class="info" v-if="hasSchedules">
        <p v-if="isPaused">PAUSADO</p>
        <p v-else>CERRADO</p>
        <p class="establishment-hours">ABRE A LAS {{ getOpensAt }}</p>
      </div>
      <div class="info" v-else>
        <p>SIN HORARIOS</p>
      </div>
    </div>
  </div>
</template>
<script>
const schedule = {
  CLOSED: 0,
  OPENED: 1,
  PAUSED: 2,
};

export default {
  name: "EstablishmentStatus",
  props: {
    establishmentStatus: null,
    establishmentSchedule: null,
    establishmentMenuName: null,
  },
  computed: {
    getClosesAt() {
      return this.establishmentSchedule.closes_at;
    },
    getOpensAt() {
      return this.establishmentSchedule.opens_at;
    },
    isPaused() {
      return this.establishmentSchedule.status === schedule.PAUSED;
    },
    hasSchedules() {
      return this.establishmentSchedule.has_schedules;
    },
    isOpened() {
      return (
        this.establishmentSchedule.status === schedule.OPENED &&
        this.establishmentStatus
      );
    },
  },
};
</script>
