<template>
  <div class="user-telephone">
    Tel: {{ number }}
    <!-- <a-button>Cerrar</a-button> -->
  </div>
</template>

<script>
export default {
  props: {
    number: null,
  },
};
</script>
