<template>
  <div>
    <a-row type="flex" justify="end">
      <a-col class="history-order-id" :span="3">
        <div class="cancel-order">
          <div class="button" @click="adjustOrder">
            {{ hasAdjustment ? "Editar ajuste" : "Crear ajuste" }}
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal
      v-model="visible"
      title="Agregar ajuste"
      class="order-adjust"
      :forceRender="true"
      :maskClosable="false"
      :afterClose="onClose"
    >
      <div class="modal-content">
        <a-row type="flex">
          <span
            ><strong>Orden </strong>
            <span class="adjust-detail">oid: {{ orderId }}</span></span
          >
        </a-row>
        <a-divider />
        <a-row type="flex">
          <span
            ><strong>- Total: </strong>
            <span class="adjust-detail-total">${{ orderWithNoAdjust }}</span></span
          >
        </a-row>
        <a-row type="flex">
          <span
            ><strong>- Ajuste anterior: </strong>
            <span v-if="hasAdjustment" class="adjust-detail-total"
              >${{ orderAdjustment }}</span
            >
            <span v-else>N/A </span></span
          >
        </a-row>
        <a-row type="flex">
          <strong>- Ajuste:</strong>
          <a-input-number
            :formatter="
              (value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            "
            v-model="total"
            class="adjust-total"
          ></a-input-number>
        </a-row>
        <a-row type="flex">
          <span
            ><strong>- Total con ajuste(s): </strong
            ><span class="adjust-detail-total"
              >${{ totalWithAdjust }}</span
            ></span
          >
        </a-row>
        <a-divider />
        <a-row type="flex">
          <small
            >Nota: El campo total con ajuste muestra la suma de la cantidad
            ingresada en <strong>Ajuste</strong> más el
            <strong>Total</strong> de la orden (considerando ajustes anteriores). 
            Al aceptar dicho cambio, la orden se actualizará mostrando un total de
            <span class="adjust-detail-total"
              >${{
                hasAdjustment ? orderWithNoAdjust : orderTotal
              }}
              +
              {{
                hasAdjustment ? `$${orderAdjustment} + $${total}` : `$${total}`
              }}
              = ${{ totalWithAdjust }}</span
            >.</small
          >
        </a-row>
      </div>
      <template slot="footer">
        <a-button key="back" @click="visible = false"> Cerrar </a-button>
        <a-popconfirm
          :title="`¿Seguro que quieres realizar un ajuste de ${total}?`"
          @confirm="onAcceptAdjust"
          @cancel="() => {}"
          okText="OK"
          cancelText="Cancelar"
        >
          <a-button class="btn-confirm"> Aceptar </a-button>
        </a-popconfirm>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "Ajust",
  data() {
    return {
      total: 0.0,
      visible: false,
    };
  },
  props: {
    order: null,
  },
  methods: {
    adjustOrder() {
      this.visible = true;
    },
    onAcceptAdjust() {
      this.visible = false;
      if (this.hasAdjustment) {
        this.total += this.orderAdjustment;
      }
      this.$emit("adjustOrder", this.total);
    },
    onClose() {
      this.total = 0.0;
    },
  },
  computed: {
    hasAdjustment() {
      return ("disponibility_adjustment" in this.order) && 
        this.order.disponibility_adjustment != "0.0";
    },
    orderAdjustment() {
      return this.order.disponibility_adjustment;
    },
    orderId() {
      return this.order.id;
    },
    orderTotal() {
      return this.order.total;
    },
    orderWithNoAdjust() {
      return +this.order.total - +this.order.disponibility_adjustment;
    },
    totalWithAdjust() {
      return +this.order.total + this.total;
    },
  },
};
</script>
