<template>
  <div class="pay-on-collect">
    <div class="history-order-totalsub">
      <a-row type="flex">
        <a-col :span="12"> → Cobrar al entregar</a-col>
        <a-col :span="12" class="total">${{ getTotal }}</a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "PayOnCollect",
  props: {
    order: null,
  },
  computed: {
    getTotal() {
      return this.order.total;
    }
  }
}
</script>